










import { defineComponent, ref, reactive } from "@vue/composition-api";

import Lottie from "vue-lottie";

import spinningCircleData from "@/assets/Lottie/SpinningCircle.json";

export default defineComponent({
  components: {
    Lottie,
  },
  setup() {
    const loadingAnimationIcon = ref();

    const loadingAnimationOptions = reactive({
      animationData: spinningCircleData,
    });

    const handleLoadingAnimation = (anim: any) => {
      loadingAnimationIcon.value = anim;
    };

    return {
      loadingAnimationOptions,
      handleLoadingAnimation,
    };
  },
});
