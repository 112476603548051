




















import { defineComponent, computed } from "@vue/composition-api";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";

import getLocalStorageCart from "@/components/Hooks/getLocalStorageCart";

import { Shop } from "@/components/Shop/Types";
import { OrderResult } from "@/components/Order/Types";

import LoadingSpinnerBox from "@/components/Common/LoadingSpinnerBox.vue";

export default defineComponent({
  components: { LoadingSpinnerBox },
  props: {
    shopInfo: { type: Object as () => Shop, required: true },
  },
  setup(props, context) {
    const { query } = context.root.$route;
    const store = context.root.$store;
    const PGPayData = computed(() => {
      if (query.pay_type === "NICEPAY_AUTH") {
        return {
          pay_type: "NICEPAY_AUTH",
          amount: query.Amt,
          auth_result_code: query.AuthResultCode,
          TID: query.TxTid,
          auth_token: query.AuthToken,
          next_app_URL: query.NextAppURL,
          auth_signature: query.Signature,
        };
      } else if (query.pay_type === "NAVERPAY_AUTH") {
        return {
          pay_type: "NAVERPAY_AUTH",
          amount: query.amount,
          payment_id: query.payment_id,
        };
      }
    });
    const selectedCartIndices = computed(() => {
      const selectedCartIndices =
        store.state.selectedCartIndices[props.shopInfo.idx];
      if (selectedCartIndices && selectedCartIndices.length > 0) {
        return selectedCartIndices;
      } else {
        return [];
      }
    });
    const removeFromCart = (shopIdx: number, removeIndices: number[]) => {
      const cartData = getLocalStorageCart();
      const currentShopCart = cartData[shopIdx];
      const restCart = currentShopCart.filter(
        (cart: any, idx: number) => !removeIndices.includes(idx)
      );
      cartData[shopIdx] = restCart;
      store.commit("setCartItems", cartData);
      localStorage.setItem("cart", JSON.stringify(cartData));
      store.commit("deleteSelectedCartIndices", { shopIdx });
    };
    const requestPayment = () => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/order/pay/shp/`, {
          order_no: query.order_no,
          pay_list: [PGPayData.value],
        })
        .then((res) => {
          const orderResult: OrderResult = camelCaseKeys(res.data.data, {
            deep: true,
          });
          if (selectedCartIndices.value.length > 0) {
            removeFromCart(props.shopInfo.idx, selectedCartIndices.value);
          }
          store.commit("deleteOrderProducts", { shopIdx: props.shopInfo.idx });
          context.root.$router.push({
            name: "OrderResult",
            params: {
              shopUrl: props.shopInfo.urlPath,
              orderNo: orderResult.orderGroupNo,
              orderResult: JSON.stringify(orderResult),
            },
          });
        })
        .catch((error) => {
          if (query.pay_type !== "NAVERPAY_AUTH") {
            alert("결제를 완료하지 못했습니다.");
          } else if (axios.isAxiosError(error)) {
            if (!error.response?.data?.data?.naverpay_result) {
              alert("결제를 완료하지 못했습니다.");
            } else {
              const code = error.response?.data.code;
              const msg = error.response?.data.data.naverpay_result.msg;
              switch (code) {
                case 501:
                  alert(msg);
                  break;
                default:
                  alert("결제를 완료하지 못했습니다.");
              }
            }
          } else {
            alert("결제를 완료하지 못했습니다.");
          }
          context.root.$router.push({
            name: "OrderPage",
            params: {
              shopUrl: props.shopInfo.urlPath,
            },
          });
        });
    };
    if (!query.is_success || query.is_success !== "True") {
      if (query.pay_type === "NAVERPAY_AUTH") {
        alert(query.msg);
      } else {
        alert("결제를 완료하지 못했습니다.");
      }
      context.root.$router.push({
        name: "OrderPage",
        params: {
          shopUrl: props.shopInfo.urlPath,
        },
      });
    } else {
      requestPayment();
    }
  },
});
